/*
    app wide HTML styles
*/
a, a:hover, a:active, a:hover {
    color: #003399;
    text-decoration: none;
}

/* SCROLLBAR */
/* Webkit */
::-webkit-scrollbar
{
    width: 7px;
}

::-webkit-scrollbar-track
{
    background: #e3e3e3;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb
{
    background: #b6bac2;
    border-radius: 4px;
}

 ::-webkit-scrollbar-thumb:hover
{
    background: #646464;
}

/* Firefox */
*
{
    scrollbar-width: thin;
    scrollbar-color: #b6bac2 #e3e3e3;
}




/*
    material font icons OVERRIDES
 */
.material-icons
{
    color: #646464;
}

.material-icons.green {
    color:  #003399;
}

/*
    material-ui overrides
*/
.MuiTypography-h5
{
    font-size: 20px;
}

.MuiTooltip-popper {
    z-index: 5001 !important;
}

.MuiPopover-root {
    z-index: 5000 !important;
}

.MuiButton-label {
    font-weight: 600;
}

.MuiButton-root.MuiButton-contained {
    /* padding:10px 25px; */
}

.MuiButton-containedSecondary:hover {
    background-color: #003399 !important;
}
.MuiButton-containedSecondary:hover .MuiButton-label {
    color: white;
}

.MuiButton-root.cancel,
.MuiButton-root.cancel:hover {
    background-color: rgba(0, 0, 0, 0);
    padding:10px 25px;
}

.MuiTab-wrapper {
    font-weight: 600;
}

.MuiSvgIcon-root.green {
    color: #003399 !important;
}

.MuiBackdrop-root {
    z-index: inherit !important;
}

.MuiCardHeader-root {
    text-align: left;
}


.MuiOutlinedInput-root:not(.MuiOutlinedInput-multiline) > .MuiOutlinedInput-input {
    /*
    IMPORTANT:
    fix for right rendering the bottom border inside a centered element via css transform
    EXCLUDED Multiline because this fix breaks the multiline size
     */
    padding-bottom: 19px !important;
}


.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #003399 !important;
}
.MuiInputLabel-outlined.Mui-focused {
    color: #003399 !important;
}

.MuiSelect-selectMenu {
    text-align: left;
}

.MuiBackdrop-root.inside-container {
    position: relative;
    height: 100%;
}

/* app wide css */

/* usefull for icons */
.rotate180 {
    transform: rotate(180deg);
}

.centerText {
  text-align: center;
  font-size: 14px;
  width: 100%;
}

.divider-with-label {
    margin-top: 20px !important;
    margin-bottom: -15px !important;
}

.divider-label {
    display: inline-block;
    text-transform: uppercase;
    padding: 5px;
    margin-bottom: 10px !important;
    background-color: white;
    color: #ccc;
}
.light-grey-text {
    color: #ccc;
}

.App {

}

#stage {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    background-color: white;
}

/* used for home, preregister, exhibit image must be set via style in component */
.homebg {
    margin-top: 0px;
    width: 100%;
    height: 100%;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
}

.tabInVisible{
    display: none !important;
}

.customConsentContainer .licenseeBtn{
    color: #003399 !important;
}
